const $ = require('jquery');

(function() {
  initializeTooltips();
})();

function initializeTooltips() {
  // initialize tooltips (bootstrap): see https://getbootstrap.com/docs/4.1/components/tooltips/
  $('[data-toggle="tooltip"]').tooltip();
}

function changeLanguage(culture) {
  var url = window.location.href
      .replace('?culture=en-US&', '?')
      .replace('?culture=en-US', '')
      .replace('&culture=en-US', '')
      .replace('?culture=de-CH&', '?')
      .replace('?culture=de-CH', '')
      .replace('&culture=de-CH', '');

  var separator = '?';

  if (url.indexOf('?') > -1)
      separator = '&';

  // intentionally using window.location.assign in order to allow testing)
  // https://stackoverflow.com/questions/7703689/difference-between-window-location-href-window-location-replace-and-window-loca/7703736
  // https://github.com/jsdom/jsdom/issues/2112
  var newUrl = (url + separator + 'culture=' + culture).replace('/?', '?');
  window.location.assign(newUrl);
  document.cookie = '.AspNetCore.Culture=c=' + culture.toLowerCase() + '|uic=' + culture.toLowerCase() + '; path=/';
}

// export as commonjs module
if (typeof module !== 'undefined' && module.exports !== null) {
  exports.changeLanguage = changeLanguage;
}
