import 'bootstrap';

// intranet styles/static files
import './extranet.scss';
import './robots.txt';
import './favicon.ico';

// images
import './images/avatar-default.png';
import './images/broken-glass.png';
import './images/uni-logo.svg';

// import areas
import { changeLanguage } from  './components/internationalization';

// register a global object where put our global scripts
if (window.unidat === undefined) {
  window.unidat = {
    internationalization: {
      changeLanguage: changeLanguage,
    },
    areas: {
    }
  };
}
